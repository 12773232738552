<template lang="">
  <ValidationProvider
    v-slot="{ errors, required }"
    :tag="`div`"
    class="grid grid-cols-12  "
    :rules="rules"
  >
    <div class="col-span-12  md:col-span-3 ">
      <div class="label ">
        <input-label
          :error="errors[0]"
          :text="`${label}${required ? ' *' : ''}`"
        />
      </div>
    </div>

    <div class="col-span-12 md:col-span-6">
      <div class="flex gap-2 items-center justify-start ">
        <div class=" flex items-center">
          <t-toggle v-model="model" :variant="`regular`" :checked="model" />
        </div>
        <span class="text-sm font-medium">{{ getStatusText }}</span>
      </div>

      <p
        v-if="showDescription"
        :class="{ 'text-red-500': errors[0] }"
        class="description mb-2"
      >
        {{ description }}
      </p>
    </div>
  </ValidationProvider>
</template>
<script>
import InputLabel from '@/components/form/InputLabel'

export default {
  name: 'SmartToggleInput',
  props: {
    showDescription: {
      type: Boolean,
      default: true,
    },

    label: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },
    onText: {
      type: String,
      default: '',
    },
    offText: {
      type: String,
      default: '',
    },
    onValue: {
      type: Boolean,
      default: true,
    },
    offValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  components: {
    InputLabel,
  },
  computed: {
    getStatusText() {
      return this.model === this.onValue ? this.onText : this.offText
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: false,
      handler() {
        this.model = this.value
      },
    },
    model: {
      immediate: false,
      deep: false,
      handler(updated, old) {
        if (updated === old) return
        this.$emit('input', updated)
        this.$emit('save', updated)
      },
    },
  },
}
</script>
<style lang="scss">
.description {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
